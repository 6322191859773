import { LinkProps } from '@remix-run/react';
import React from 'react';

// eslint-disable-next-line no-restricted-imports
import { Button, ButtonProps } from '@mui/material';

import { I18nLink } from './i18n-link';

//
//

type PrimaryButtonProps = {
  color: 'primary';
  variant: 'contained';
};

type SecondaryButtonProps = {
  color: 'secondary';
  variant: 'text' | 'outlined';
};

type ErrorButtonProps = {
  color: 'error';
  variant: 'text' | 'outlined' | 'contained';
};

type NoColorButtonProps = {
  color?: undefined;
  variant?: 'text' | 'outlined' | 'contained';
};

export type AppButtonProps = Partial<LinkProps> &
  Omit<ButtonProps, 'color' | 'variant'> & {
    content?: React.ReactNode;
  } & (PrimaryButtonProps | SecondaryButtonProps | ErrorButtonProps | NoColorButtonProps);

export const AppButton: React.FC<AppButtonProps> = ({
  viewTransition = true,
  variant = 'contained',
  to,
  href,
  color,
  children,
  content,
  ...props
}: AppButtonProps) => {
  const resolvedColor =
    color ||
    (() => {
      switch (variant) {
        case 'outlined':
        case 'text':
          return 'secondary';
        case 'contained':
        default:
          return 'primary';
      }
    })();

  if (to || href) {
    (props as LinkProps).viewTransition = viewTransition ?? true;
  }

  return (
    <Button
      component={to || href ? I18nLink : 'button'}
      color={resolvedColor}
      variant={variant}
      to={to}
      href={href}
      {...props}
    >
      {content}
      {children}
    </Button>
  );
};
